<template>
  <div v-if="$isLoading">
    <b-row class="no-gutters">
      <b-col>
        <h1 class="font-weight-bold header-main text-uppercase mb-3">
          ข้อมูลแบนเนอร์
        </h1>
      </b-col>
    </b-row>
    <div class="bg-white px-3">
      <b-row class="pb-3">
        <b-col cols="6">
          <div class="panel d-flex align-items-md-center ml-2">
            <b-form-checkbox
              size="lg"
              id="sameLang"
              class="mt-0 mt-sm-3"
              @change="useSameLanguage"
              v-model="form.isSameLanguage"
              >ใช้เหมือนกันทุกภาษา
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="6" class="text-right">
          <div class="panel mt-3">
            <b-button
              type="button"
              class="btn btn-language"
              v-for="(language, index) in languageList"
              v-bind:key="index"
              v-bind:class="[languageActive == language.id ? 'active' : '']"
              @click="changeLanguage(language.id, index)"
              :disabled="form.isSameLanguage ? true : false"
            >
              <span class="text-uppercase">{{ language.nation }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="no-gutters">
        <b-col>
          <b-row>
            <b-col>
              <InputText
                textFloat="ชื่อแบนเนอร์"
                placeholder="ชื่อแบนเนอร์"
                type="text"
                name="name"
                v-model="form.name"
                @onKeyup="setAlttagByLang(form.name)"
                isRequired
                :isValidate="$v.form.name.$error"
                :v="$v.form.name"
              />
            </b-col>
            <b-col>
              <div
                v-for="(item, index) in form.translationList"
                v-bind:key="index"
              >
                <div
                  v-bind:class="[
                    languageActive == item.languageId ? '' : 'd-none',
                  ]"
                >
                  <InputText
                    textFloat="Alt tag"
                    placeholder="Alt tag"
                    type="text"
                    name="name"
                    :img="imageLogoLang"
                    v-model="item.altTag"
                    isRequired
                    @onKeyup="setAlttagByLang(item.altTag, index)"
                    :isValidate="
                      $v.form.translationList.$each.$iter[index].altTag.$error
                    "
                    :v="$v.form.translationList.$each.$iter[index].altTag"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <InputText
                textFloat="ลำดับการแสดง"
                placeholder="ลำดับการแสดง"
                type="number"
                name="sortorder"
                @onKeypress="isNumber($event)"
                v-model="form.sortOrder"
              />
            </b-col>
            <b-col>
              <InputText
                textFloat="ลิ้งค์ไปยังภายนอก"
                placeholder="ลิ้งค์ไปยังภายนอก"
                type="text"
                name="linkurl"
                v-model="form.link"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <UploadFile
                textFloat="ภาพแบนเนอร์สำหรับคอมพิวเตอร์"
                placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
                format="image"
                name="thumbnail"
                :fileName="fileNameComputer"
                text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 16:9 ขนาดไม่เกิน 50 MB"
                isRequired
                v-on:onFileChange="onImageChange"
                v-on:delete="deleteImage"
                :v="$v.fileNameComputer"
              />
              <div
                class="preview-box previre-ratio-5625"
                v-bind:style="{
                  'background-image': 'url(' + showPreview + ')',
                }"
              >
                <img
                  src="/img/loading.svg"
                  class="loading"
                  alt="loading"
                  v-if="isLoadingImage"
                />
              </div>
            </b-col>
            <b-col md="6">
              <UploadFile
                textFloat="ภาพแบนเนอร์สำหรับมือถือ"
                placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
                format="image"
                name="thumbnail"
                :fileName="fileNameMobile"
                text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 10:4 ขนาดไม่เกิน 50 MB"
                isRequired
                v-on:onFileChange="onMobileImageChange"
                v-on:delete="deleteMobileImage"
                :v="$v.fileNameMobile"
              />
              <div
                class="preview-box ratio-5-3-pb"
                v-bind:style="{
                  'background-image': 'url(' + mobileShowPreview + ')',
                }"
              >
                <img
                  src="/img/loading.svg"
                  class="loading"
                  alt="loading"
                  v-if="isLoadingMobileImage"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <div class="bg-white p-3 mt-3">
      <b-row>
        <b-col
          class="form-inline footer-paginate justify-content-center justify-content-sm-between"
        >
          <!-- <div class="d-sm-flex">
              <label class="font-weight-bold main-label">
                การใช้งาน
                <span class="text-danger">*</span>
              </label>
              <div>
                <b-form-checkbox
                  switch
                  class="radio-active ml-3"
                  size="lg"
                  v-model="form.status"
                >
                  <span class="ml-2 main-label">
                    {{ form.status ? "เปิด" : "ปิด" }}
                  </span>
                </b-form-checkbox>
              </div>
            </div> -->
        </b-col>
        <b-col class="d-flex justify-content-end align-items-center">
          <router-link :to="'/setting-banner/details/' + $route.params.id">
            <b-button class="button btn-cancel ml-2"
              >ย้อนกลับ</b-button
            ></router-link
          >
          <b-button class="btn-main ml-3" @click="checkForm()">บันทึก</b-button>
        </b-col>
      </b-row>
    </div>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import { required } from "vuelidate/lib/validators";
import UploadFile from "@/components/inputs/UploadFile";
import Vue from "vue";
import { mapState } from "vuex";

export default {
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    UploadFile,
  },
  data() {
    return {
      id: this.$route.params.id,
      isDisable: false,
      modalMessage: "",
      languageList: [],
      languageActive: 1,
      imageLogoLang: "",
      fileNameComputer: "",
      fileNameMobile: "",
      imageBase64: null,
      mobileImageBase64: null,
      isLoadingImage: false,
      isLoadingMobileImage: false,
      coverImgType: 1,
      showVideo: "",
      showPreview: "",
      mobileCoverImgType: 1,
      mobileShowVideo: "",
      mobileShowPreview: "",
      form: {
        id: 0,
        link: "",
        imageUrl: "",
        imageRatioId: 1,
        mobileImageUrl: "",
        mobileImageRatioId: 1,
        isSameLanguage: true,
        name: "",
        sortOrder: 0,
        translationList: [
          {
            languageId: 1,
            altTag: null,
          },
          {
            languageId: 2,
            altTag: null,
          },
        ],
      },
      data: {},
      timer: null,
    };
  },
  validations: {
    fileNameComputer: { required },
    fileNameMobile: { required },
    form: {
      name: { required },
      translationList: {
        $each: {
          altTag: { required },
        },
      },
    },
  },
  computed: {
    ...mapState({
      indexBannerDetail: (state) => state.indexBannerDetail,
      bannerDetail: (state) => state.bannerDetail,
    }),
  },
  created: async function () {
    await this.getLanguages();
    if (this.indexBannerDetail != null) {
      this.form = this.bannerDetail.details[this.indexBannerDetail];
      this.showPreview = this.form.imageUrl;
      this.fileNameComputer = this.form.imageUrl;
      this.mobileShowPreview = this.form.mobileImageUrl;
      this.fileNameMobile = this.form.mobileImageUrl;
      this.$isLoading = true;
    } else {
      await this.getDatas();
    }
    await this.changeLanguage(1, 0);
  },
  methods: {
    async changeLanguage(id, index) {
      if (!this.form.isSameLanguage) {
        this.languageActive = id;
        this.imageLogoLang = this.languageList[index].imageUrl;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getLanguages: async function () {
      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );
      if (languages.result == 1) {
        this.languageList = languages.detail;
      }
    },
    getDatas: async function () {
      this.$isLoading = false;

      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );
      if (languages.result == 1) {
        this.languageList = languages.detail;
      }

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Template/Section/${this.id}`,
        null,
        this.$headersSetting,
        null
      );

      if (data.result == 1) {
        this.data = data.detail;
        if (data.detail.details && data.detail.details.length) {
          var index = data.detail.details.findIndex(
            (el) => el.id == this.$route.params.bannerid
          );
          if (index != -1) {
            this.form = data.detail.details[index];
            this.showPreview = this.form.imageUrl;
            this.fileNameComputer = this.form.imageUrl;
            this.mobileShowPreview = this.form.mobileImageUrl;
            this.fileNameMobile = this.form.mobileImageUrl;
          }
        }
        this.$v.$reset();
      }
      this.$isLoading = true;
    },
    useSameLanguage: async function () {
      Vue.nextTick(() => {
        if (this.form.isSameLanguage) {
          this.imageLogoLang = "";
          this.form.mainLanguageId = this.languageActive;
          let data = this.form.translationList.filter(
            (val) => val.languageId == this.form.mainLanguageId
          );
          if (data.length == 1) {
            data = data[0];
            for (
              let index = 0;
              index < this.form.translationList.length;
              index++
            ) {
              this.form.translationList[index].altTag = data.altTag;
              this.form.altTag = data.altTag;
              this.form.alttag_en = data.altTag;
            }
          }

          if (this.data.details) {
            for (let i = 0; i < this.data.details.length; i++) {
              if (this.data.details[i].id != this.$route.params.bannerid) {
                for (
                  let index = 0;
                  index < this.data.details[i].translationList.length;
                  index++
                ) {
                  if (index == 1) {
                    this.data.details[i].alttag_en =
                      this.data.details[i].translationList[index].altTag;
                  } else {
                    this.data.details[i].altTag =
                      this.data.details[i].translationList[index].altTag;
                  }
                }
              }
            }
          }
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);
          this.imageLogoLang = this.languageList[index].imageUrl;

          let data = this.form.translationList.filter(
            (val) => val.languageId != this.form.mainLanguageId
          );
          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              data.altTag = "";
            }
          }
        }
      });
    },
    checkValidateTranslationList: async function () {
      let isError = false;
      this.languageList.forEach((element, index) => {
        if (!isError) {
          if (this.$v.form.translationList.$each.$iter[index].$error) {
            this.languageActive =
              this.$v.form.translationList.$model[index].languageId;
            this.imageLogoLang = this.languageList[index].imageUrl;
            isError = true;
          }
        }
      });
    },
    setAlttagByLang: function (value, index) {
      if (this.form.isSameLanguage) {
        for (const e of this.form.translationList) {
          e.altTag = value ? value.replace(/ /g, "-") : "";
        }
        // this.form.translationList[index].altTag = value.replace(/ /g, "-");
      }
    },
    checkForm: async function () {

      if (this.form.isSameLanguage) {
        await this.useSameLanguage();
      } else {
        if (this.data.details) {
          for (const e of this.data.details) {
            for (let index = 0; index < e.translationList.length; index++) {
              if (index == 1) {
                e.alttag_en = e.translationList[index].altTag;
              } else {
                e.altTag = e.translationList[index].altTag;
              }
            }
          }
        }
        for (let index = 0; index < this.form.translationList.length; index++) {
          if (index == 1) {
            this.form.alttag_en = this.form.translationList[index].altTag;
          } else {
            this.form.altTag = this.form.translationList[index].altTag;
          }
        }
      }
      this.$v.$touch();
      if (this.$v.$error) {
        await this.checkValidateTranslationList();
        if (document.querySelector(".input-custom.error")) {
          this.$nextTick(() => {
            let domRect = document
              .querySelector(".input-custom.error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 150
            );
          });
        }
        return;
      }
      this.form.sortOrder = this.form.sortOrder
        ? parseInt(this.form.sortOrder)
        : 0;
      if (this.id != 0) {
        var index = this.data.details.findIndex((el) => el.id == this.form.id);
        if (index != -1) this.data.details[index] = this.form;
        else this.data.details.push(this.form);
        await this.submit();
      } else {
        this.$refs.modalLoading.show();
        let obj = {
          flag: true,
          data: this.form,
          index: this.indexBannerDetail,
        };
        this.$store.commit("setBannerDetail", obj);
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.$refs.modalLoading.hide();
          this.modalMessage = "สำเร็จ";
          this.$refs.modalAlert.show();
          setTimeout(() => {
            this.$router.push({
              path: `/setting-banner/details/${this.id}`,
            });
          }, 1500);
        }, 1500);
      }
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        //this.images = reader.result;
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;

        this.showPreview = this.images;
        this.form.imageUrl = this.images;
        this.fileNameComputer = this.images;

        if (img.type == "video/mp4") {
          this.coverImgType = 2;
          var vid = this.$refs.videoRef;
          if (vid != undefined) {
            vid.load();
          }
        } else {
          this.coverImgType = 1;
        }
      };
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        base64: img,
        type: this.imgTypeId,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    deleteImage() {
      this.form.imageUrl = null;
      this.form.imageBase64 = null;
      this.fileNameComputer = "";
      this.showPreview = null;

      if (this.coverImgType == 2) {
        var vid = this.$refs.videoRef;
        vid.load();
        this.coverImgType = 1;
      }
    },
    onMobileImageChange(img) {
      this.isLoadingMobileImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        //this.mobileImages = reader.result;
        this.mobileImages = await this.saveImagetoDb(reader.result);
        this.isLoadingMobileImage = false;
        this.isDisable = false;

        this.mobileShowPreview = this.mobileImages;
        this.form.mobileImageUrl = this.mobileImages;
        this.fileNameMobile = this.mobileImages;

        if (img.type == "video/mp4") {
          this.mobileCoverImgType = 2;
          var vid = this.$refs.videoMobileRef;
          if (vid != undefined) {
            vid.load();
          }
        } else {
          this.mobileCoverImgType = 1;
        }
      };
    },
    deleteMobileImage() {
      this.form.mobileImageUrl = null;
      this.form.mobileImageBase64 = null;
      this.fileNameMobile = "";
      this.mobileShowPreview = null;

      if (this.mobileCoverImgType == 2) {
        var vid = this.$refs.videoMobileRef;
        vid.load();
        this.mobileCoverImgType = 1;
      }
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Template/Section/Save`,
        null,
        this.$headersSetting,
        this.data
      );

      this.$refs.modalLoading.hide();
      this.modalMessage = data.message;
      this.isDisable = false;
      if (data.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$router.push({
            path: `/setting-banner/details/${this.id}`,
          });
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
::v-deep .panel-input-serach {
  border: 1px solid #bcbcbc !important;
  max-width: 350px;
}

::v-deep .card {
  margin-bottom: 0 !important;
}

/* ::v-deep .preview-box {
  aspect-ratio: 16/9 !important;
  padding-bottom: 0 !important;
} */
</style>
