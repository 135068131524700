var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$isLoading)?_c('div',[_c('b-row',{staticClass:"no-gutters"},[_c('b-col',[_c('h1',{staticClass:"font-weight-bold header-main text-uppercase mb-3"},[_vm._v(" ข้อมูลแบนเนอร์ ")])])],1),_c('div',{staticClass:"bg-white px-3"},[_c('b-row',{staticClass:"pb-3"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"panel d-flex align-items-md-center ml-2"},[_c('b-form-checkbox',{staticClass:"mt-0 mt-sm-3",attrs:{"size":"lg","id":"sameLang"},on:{"change":_vm.useSameLanguage},model:{value:(_vm.form.isSameLanguage),callback:function ($$v) {_vm.$set(_vm.form, "isSameLanguage", $$v)},expression:"form.isSameLanguage"}},[_vm._v("ใช้เหมือนกันทุกภาษา ")])],1)]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('div',{staticClass:"panel mt-3"},_vm._l((_vm.languageList),function(language,index){return _c('b-button',{key:index,staticClass:"btn btn-language",class:[_vm.languageActive == language.id ? 'active' : ''],attrs:{"type":"button","disabled":_vm.form.isSameLanguage ? true : false},on:{"click":function($event){return _vm.changeLanguage(language.id, index)}}},[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(language.nation))])])}),1)])],1),_c('b-row',{staticClass:"no-gutters"},[_c('b-col',[_c('b-row',[_c('b-col',[_c('InputText',{attrs:{"textFloat":"ชื่อแบนเนอร์","placeholder":"ชื่อแบนเนอร์","type":"text","name":"name","isRequired":"","isValidate":_vm.$v.form.name.$error,"v":_vm.$v.form.name},on:{"onKeyup":function($event){return _vm.setAlttagByLang(_vm.form.name)}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('b-col',_vm._l((_vm.form.translationList),function(item,index){return _c('div',{key:index},[_c('div',{class:[
                  _vm.languageActive == item.languageId ? '' : 'd-none',
                ]},[_c('InputText',{attrs:{"textFloat":"Alt tag","placeholder":"Alt tag","type":"text","name":"name","img":_vm.imageLogoLang,"isRequired":"","isValidate":_vm.$v.form.translationList.$each.$iter[index].altTag.$error,"v":_vm.$v.form.translationList.$each.$iter[index].altTag},on:{"onKeyup":function($event){return _vm.setAlttagByLang(item.altTag, index)}},model:{value:(item.altTag),callback:function ($$v) {_vm.$set(item, "altTag", $$v)},expression:"item.altTag"}})],1)])}),0)],1),_c('b-row',[_c('b-col',[_c('InputText',{attrs:{"textFloat":"ลำดับการแสดง","placeholder":"ลำดับการแสดง","type":"number","name":"sortorder"},on:{"onKeypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.form.sortOrder),callback:function ($$v) {_vm.$set(_vm.form, "sortOrder", $$v)},expression:"form.sortOrder"}})],1),_c('b-col',[_c('InputText',{attrs:{"textFloat":"ลิ้งค์ไปยังภายนอก","placeholder":"ลิ้งค์ไปยังภายนอก","type":"text","name":"linkurl"},model:{value:(_vm.form.link),callback:function ($$v) {_vm.$set(_vm.form, "link", $$v)},expression:"form.link"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('UploadFile',{attrs:{"textFloat":"ภาพแบนเนอร์สำหรับคอมพิวเตอร์","placeholder":"กรุณาเลือกไฟล์เพื่ออัพโหลด","format":"image","name":"thumbnail","fileName":_vm.fileNameComputer,"text":"*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 16:9 ขนาดไม่เกิน 50 MB","isRequired":"","v":_vm.$v.fileNameComputer},on:{"onFileChange":_vm.onImageChange,"delete":_vm.deleteImage}}),_c('div',{staticClass:"preview-box previre-ratio-5625",style:({
                'background-image': 'url(' + _vm.showPreview + ')',
              })},[(_vm.isLoadingImage)?_c('img',{staticClass:"loading",attrs:{"src":"/img/loading.svg","alt":"loading"}}):_vm._e()])],1),_c('b-col',{attrs:{"md":"6"}},[_c('UploadFile',{attrs:{"textFloat":"ภาพแบนเนอร์สำหรับมือถือ","placeholder":"กรุณาเลือกไฟล์เพื่ออัพโหลด","format":"image","name":"thumbnail","fileName":_vm.fileNameMobile,"text":"*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 10:4 ขนาดไม่เกิน 50 MB","isRequired":"","v":_vm.$v.fileNameMobile},on:{"onFileChange":_vm.onMobileImageChange,"delete":_vm.deleteMobileImage}}),_c('div',{staticClass:"preview-box ratio-5-3-pb",style:({
                'background-image': 'url(' + _vm.mobileShowPreview + ')',
              })},[(_vm.isLoadingMobileImage)?_c('img',{staticClass:"loading",attrs:{"src":"/img/loading.svg","alt":"loading"}}):_vm._e()])],1)],1)],1)],1)],1),_c('div',{staticClass:"bg-white p-3 mt-3"},[_c('b-row',[_c('b-col',{staticClass:"form-inline footer-paginate justify-content-center justify-content-sm-between"}),_c('b-col',{staticClass:"d-flex justify-content-end align-items-center"},[_c('router-link',{attrs:{"to":'/setting-banner/details/' + _vm.$route.params.id}},[_c('b-button',{staticClass:"button btn-cancel ml-2"},[_vm._v("ย้อนกลับ")])],1),_c('b-button',{staticClass:"btn-main ml-3",on:{"click":function($event){return _vm.checkForm()}}},[_vm._v("บันทึก")])],1)],1)],1),_c('ModalAlert',{ref:"modalAlert",attrs:{"text":_vm.modalMessage}}),_c('ModalAlertError',{ref:"modalAlertError",attrs:{"text":_vm.modalMessage}}),_c('ModalLoading',{ref:"modalLoading",attrs:{"hasClose":false}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }